import React, { useState,useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './index.scss';

const news = [
  "Crypto Market Insights Lab",
  "Block Hub News Daily",
  "Crypto World News Port",
  "Token Crypto Update",
  "Decentral Insights News",
  "Block News Insider",
  "Token Media Wave",
  "Crypto Finance News",
  "Web3 Token Watcher",
  "Chain Crypto Daily",
  "Token World Media",
  "Decentral DeFi News",
  "Block Explore News",
  "Token Web3 Tracker",
  "Crypto Network News",
  "Chain Future Insights",
  "Block Flow News",
  "Crypto Insight Daily Hub",
  "Token Explore News",
  "Decentral Crypto Update",
  "Web3 Token Hub",
  "Blockchain News Port",
  "Crypto Vision Insider",
  "Token Market Report",
  "Web3 Chain Insights",
  "Chain Block News Daily",
  "Block Token Media Hub",
  "Crypto Trending News",
  "DeFi Crypto Update",
  "Token News Digest",
  "Blockchain Snapshot News",
  "Web3 Token News",
  "DeFi Market Pulse",
  "Token Future Trends",
  "Crypto Market Watchdog",
  "Blockchain Insights Lab",
  "Crypto Alerts Center",
  "Token Flow News",
  "Block Crypto Updates",
  "Decentra News Insider",
  "Web3 Crypto Media",
  "Token News Insight",
  "Blockchain Market Report",
  "Crypto Token Vision",
  "Chain News Daily Hub",
  "Decentral Crypto Media",
  "Token News",
  "Crypto Market Pulse Hub",
  "Web3 Insights Daily",
  "Token Block Trends",
  "Crypto Space Updates",
  "Crypto News Watcher",
  "Blockchain Explore News",
  "DeFi Hub News"
];

const LatestUpdates = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      const interval = setInterval(() => {
        setCurrentNewsIndex((prevIndex) => (prevIndex + 1) % news.length);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [isOpen]);

  return (
    <motion.div 
      className="drawer"
      animate={{ 
        width: isOpen ? 360 : 40
      }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      onClick={toggleDrawer}
    >
      <AnimatePresence mode="wait">
        {!isOpen && (
          <motion.span
            key="emoji"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="emoji"
          >
            <img src={'https://cdn.prod.website-files.com/6311b1064c17c145853790e7/631228873157c40a9fdfdd38_custom-emoji-pepe-surprise.gif'} width={40} alt="latest-updates" />
          </motion.span>
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {isOpen && (
          <motion.span
            key="text"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2, delay: 0.1 }}
            className="text"
          >
            <span className='reported-by'>🎉&nbsp;&nbsp;Reported by the following media: </span>
            <motion.div className="news-scroll-container">
              <AnimatePresence mode="wait">
                <motion.span
                  key={currentNewsIndex}
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -20, opacity: 0 }}
                  transition={{ 
                    y: { type: "spring", stiffness: 300, damping: 30 },
                    opacity: { duration: 0.2 }
                  }}
                  className="text"
                >
                  {news[currentNewsIndex]}
                </motion.span>
              </AnimatePresence>
            </motion.div>
          </motion.span>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default LatestUpdates;

